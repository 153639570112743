import { Grid } from '@mui/material';

import CONTENT from '../../constants/content.json';
import SubtitleText from '../text/SubtitleText';
import BodyText from '../text/BodyText';

function Education() {
  const educationComponent = CONTENT.SECTIONS.EDUCATION.map((item) => {
    // Get the information from the item
    const { NAME, DEGREE, DESCRIPTION, LINK, IMAGE } = item;

    return (
      <Grid item md={6} sm={12} xs={12} key={NAME}>
        <div className="education-item">
          <a href={LINK} target="_blank" rel="noreferrer" className="mb-1">
            <img src={IMAGE} alt={`${NAME} Logo`} />
          </a>
          <a href={LINK} target="_blank" rel="noreferrer">
            <SubtitleText customClass="bold" color="yellow" animate={false}>
              {NAME}
            </SubtitleText>
          </a>
          <BodyText bold delay={0.2}>{DEGREE}</BodyText>
          <BodyText delay={0.4}>{DESCRIPTION}</BodyText>
        </div>
      </Grid>
    );
  });

  return (
    <Grid container className="education-wrap">
      {educationComponent}
    </Grid>
  );
}

export default Education;
