import PropTypes from 'prop-types';

function getTextAnim(initX, finalX, initY, finalY, fadeIn, delay) {
  // Create the basic animation
  const anim = {
    init: {
      x: initX,
      y: initY,
    },
    after: {
      x: finalX,
      y: finalY,
      transition: {
        delay,
        duration: 0.5,
      },
    },
  };

  // If fade in required, change the opacity
  if (fadeIn) {
    anim.init.opacity = 0;
    anim.after.opacity = 1;
  }

  return anim;
}

const textProps = {
  customClass: PropTypes.string,
  color: PropTypes.oneOf(['white', 'yellow']),
  bold: PropTypes.bool,
  animate: PropTypes.bool,
  initX: PropTypes.any,
  finalX: PropTypes.any,
  initY: PropTypes.any,
  finalY: PropTypes.any,
  fadeIn: PropTypes.bool,
  delay: PropTypes.number,
  children: PropTypes.node,
}

const viewport = { once: true, margin: '-40px' };

export { getTextAnim, textProps, viewport };
