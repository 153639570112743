import CONTENT from '../constants/content.json'
import ImageBillboard from '../components/billboards/ImageBillboard';
import Header from '../components/headers/Header';
import SectionTitle from '../components/titles/SectionTitle';
import PageTitle from '../components/titles/PageTitle';
import Footer from '../components/headers/Footer';
import Skills from '../components/sections/Skills';
import Education from '../components/sections/Education';

function About() {
  const aboutContent = CONTENT.ABOUT;
  return (
    <>
      <Header />
      <ImageBillboard img={aboutContent.IMAGE} alt={aboutContent.IMAGE_ALT} />
      <main>
        <PageTitle bold={aboutContent.TITLE} normal={aboutContent.SUBTITLE}>
          {aboutContent.DESCRIPTION}
        </PageTitle>
        <section>
          <SectionTitle title="SKILLS" />
          <Skills />
        </section>
        <section className="pb-0">
          <SectionTitle title="EDUCATION" />
          <Education />
        </section>
      </main>
      <Footer />
    </>
  );
}

export default About;
