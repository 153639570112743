import PropTypes from 'prop-types';

import BodyText from '../text/BodyText';
import TitleText from '../text/TitleText';

function PageTitle({ bold, normal, children }) {
  return (
    <section>
      <div>
        <TitleText bold customClass="mb-1">
          {bold}
        </TitleText>
        <TitleText delay={0.2}>{normal}</TitleText>
        <br />
        <BodyText delay={0.4}>{children}</BodyText>
      </div>
    </section>
  );
}

PageTitle.propTypes = {
  bold: PropTypes.string,
  normal: PropTypes.string,
  children: PropTypes.node,
};

export default PageTitle;
