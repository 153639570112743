import CONTENT from '../constants/content.json';
import ImageBillboard from '../components/billboards/ImageBillboard';
import Header from '../components/headers/Header';
import ContactForm from '../components/forms/ContactForm';
import Footer from '../components/headers/Footer';
import PageTitle from '../components/titles/PageTitle';

function Contact() {
  const contactContent = CONTENT.CONTACT;
  return (
    <>
      <Header />
      <ImageBillboard img={contactContent.IMAGE} alt={contactContent.IMAGE_ALT} />
      <main>
        <PageTitle bold={contactContent.TITLE} normal={contactContent.SUBTITLE}>
          {contactContent.DESCRIPTION}
        </PageTitle>
        <ContactForm />
      </main>
      <Footer />
    </>
  );
}

export default Contact;
