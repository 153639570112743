import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import Billboard from './Billboard';

function ImageBillboard({ img, alt }) {
  // Create the image animation
  const imgAnim = {
    hidden: { opacity: 0, y: '15%' },
    show: {
      opacity: 1,
      y: '10%',
      transition: {
        duration: 1,
      },
    },
  };

  // Initialize an image component
  const imgComponent = (
    <motion.img src={img} alt={alt} variants={imgAnim} initial="hidden" animate="show" />
  );

  return (
    <Billboard size="md" bgColor="yellow">
      {imgComponent}
    </Billboard>
  );
}

ImageBillboard.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
};

export default ImageBillboard;
