function getTextAnimation(color = 'yellow') {
  return {
    init: { pathLength: 0 },
    after: {
      pathLength: 1,
      fill: ['#232323', '#FFE66D'],
      transition: {
        pathLength: { duration: 4 },
        fill: { delay: 0.75, duration: 0.5 },
      },
    },
  };
}

export { getTextAnimation };
