import { useRef } from 'react';
import emailjs from '@emailjs/browser';

import BodyText from '../text/BodyText';

function ContactForm() {
  const form = useRef();

  // Handle form submission request
  const submitForm = async (e) => {
    e.preventDefault();

    emailjs.sendForm('portfolio_service', 'portfolio_template', form.current, 'ooolc2Wqu20xB-HtV').then(
      (res) => {
        // TODO: Add a success indicator
      },
      (err) => {
        // TODO: Add an error indicator
      },
    );
  };

  return (
    <section>
      <form ref={form} onSubmit={submitForm}>
        <BodyText bold color="yellow" initY="1em" customClass="label">
          Name
        </BodyText>
        <input name="NAME" type="text" />

        <BodyText bold color="yellow" initY="1em" customClass="label">
          Email
        </BodyText>
        <input name="EMAIL" type="email" />

        <BodyText bold color="yellow" initY="1em" customClass="label">
          Message
        </BodyText>
        <textarea name="MESSAGE" />

        <button type="submit">Submit</button>
      </form>
    </section>
  );
}

export default ContactForm;
