function getContainerAnim(delay = 0) {
  return {
    init: { opacity: 1 },
    after: {
      transition: {
        staggerChildren: 0.3,
        delayChildren: delay,
      },
    },
  };
}

// Creates a link animation based on the y value provided
function getLinkAnim(y = 100) {
  return {
    init: { y },
    after: {
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
}

const viewport = { once: true, margin: '-40px' };

export { getContainerAnim, getLinkAnim, viewport };
