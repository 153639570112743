import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import CONTENT from '../../constants/content.json';
import BodyText from '../text/BodyText';

function Skills() {
  const skillsContent = CONTENT.SECTIONS.SKILLS;
  return (
    <>
      <SkillWrap values={skillsContent.LANGUAGES} />
      <hr className="full" />
      <SkillWrap values={skillsContent.TOOLS} />
    </>
  );
}

function SkillWrap({ values }) {
  // Create the container animation
  const containerAnim = {
    init: { opacity: 1 },
    after: { transition: { staggerChildren: 0.3 } },
  };

  // Create the view information
  const view = { once: true, margin: '-75px' };

  // Map the values provided to a new component
  const skillComponent = values.map((item) => {
    // Get the name, level, and description from each item
    const { NAME, LEVEL, DESCRIPTION } = item;

    return (
      <motion.div
        variants={containerAnim}
        initial="init"
        whileInView="after"
        viewport={view}
        className="skill-wrap"
        key={NAME}
      >
        <BodyText color="yellow" customClass="label" animate={false} bold>
          {NAME}
        </BodyText>
        <SkillBar key={NAME} amount={LEVEL} />
        <BodyText customClass="value" initY="1em" delay={0.3}>
          {DESCRIPTION}
        </BodyText>
      </motion.div>
    );
  });

  // Return the skill component
  return <>{skillComponent}</>;
}

SkillWrap.propTypes = {
  values: PropTypes.array,
};

function SkillBar({ amount }) {
  // Get the fill width value
  const fillWidth = (amount / 5) * 100;

  // Create the fill animation
  const fillAnim = {
    init: { width: 0 },
    after: { width: `${fillWidth}%`, transition: { duration: 0.6 } },
  };

  // Create an array of all the bars
  const bars = [];

  // Loop over the amount of bars needed
  for (let i = 0; i < 5; i++) bars.push(<div key={i} className="bar" />);

  // Return the bars array
  return (
    <div className="bar-wrap">
      {bars}
      <motion.div variants={fillAnim} className="fill" />
    </div>
  );
}

SkillBar.propTypes = {
  amount: PropTypes.number,
};

export default Skills;
