import CONTENT from '../constants/content.json';
import Header from '../components/headers/Header';
import Billboard from '../components/billboards/Billboard';
import Name from '../components/svg/Name';

function Home() {
  const homeContent = CONTENT.HOME;
  return (
    <Billboard>
      <div className="c-yellow">
        <h2>{homeContent.TITLE}</h2>
        <Name />
      </div>
      <Header bottom />
    </Billboard>
  );
}

export default Home;
