import CONTENT from '../constants/content.json';
import ImageBillboard from '../components/billboards/ImageBillboard';
import Header from '../components/headers/Header';
import SectionTitle from '../components/titles/SectionTitle';
import Footer from '../components/headers/Footer';
import Experience from '../components/sections/Experience';
import Projects from '../components/sections/Projects';

function Work() {
  const workContent = CONTENT.WORK;
  return (
    <>
      <Header />
      <ImageBillboard img={workContent.IMAGE} alt={workContent.IMAGE_ALT} />
      <main>
        <section>
          <SectionTitle title="EXPERIENCE" />
          <Experience />
        </section>
        <section className="pb-0">
          <SectionTitle title="PROJECTS" />
          <Projects />
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Work;
