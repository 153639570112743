import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

function SectionTitle({ title }) {
  const lineAnim = {
    init: { width: '0' },
    after: { width: '4em', transition: { duration: 0.5 } },
  };
  const view = { once: true };

  return (
    <div className="center-left">
      <h2 className="c-yellow">{title}&nbsp;</h2>
      <motion.hr variants={lineAnim} initial="init" whileInView="after" viewport={view} />
    </div>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string,
};

export default SectionTitle;
