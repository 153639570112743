import { motion } from 'framer-motion';

import { getTextAnim, textProps, viewport } from '.';

function TitleText({
  customClass = '',
  color = 'yellow',
  bold = false,
  animate = true,
  initX = 0,
  finalX = 0,
  initY = 0,
  finalY = 0,
  fadeIn = true,
  delay = 0.1,
  children,
}) {
  let className = `${customClass} c-${color}`;
  if (bold) className += ` bold`;

  // If we are not animating, return a regular H2
  if (animate === false) return <h2 className={className}>{children}</h2>;

  const textAnim = getTextAnim(initX, finalX, initY, finalY, fadeIn, delay);
  return (
    <motion.h2
      className={className}
      variants={textAnim}
      initial="init"
      whileInView="after"
      viewport={viewport}
    >
      {children}
    </motion.h2>
  );
}

TitleText.propTypes = textProps;

export default TitleText;
