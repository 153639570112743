import { Grid } from '@mui/material';

import CONTENT from '../../constants/content.json';
import SubtitleText from '../text/SubtitleText';
import BodyText from '../text/BodyText';

function Projects() {
  const projectsComponent = CONTENT.SECTIONS.PROJECTS.map((item) => {
    // Get the information from the item
    const { TITLE, DATE, DESCRIPTION, LINK, IMAGE } = item;

    return (
      <Grid item md={6} sm={12} xs={12} key={TITLE}>
        <div className="projects-item">
          <a href={LINK} target="_blank" rel="noreferrer" className="mb-1">
            <img src={IMAGE} alt={`${TITLE}`} />
          </a>
          <a href={LINK} target="_blank" rel="noreferrer">
            <SubtitleText customClass="bold" color="yellow" animate={false}>
              {TITLE}
            </SubtitleText>
          </a>
          <BodyText bold delay={0.2}>{DATE}</BodyText>
          <BodyText delay={0.4}>{DESCRIPTION}</BodyText>
        </div>
      </Grid>
    );
  });

  return (
    <Grid container className="projects-wrap">
      {projectsComponent}
    </Grid>
  );
}

export default Projects;
