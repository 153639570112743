import { motion } from 'framer-motion';

import CONTENT from '../../constants/content.json';
import { getContainerAnim, getLinkAnim, viewport } from '.';

function Footer() {
  const containerAnim = getContainerAnim();
  const linkAnim = getLinkAnim();

  const linkComponent = CONTENT.FOOTER.map((item) => {
    const { NAME, LINK, IMAGE } = item;
    return (
      <motion.a key={NAME} href={LINK} variants={linkAnim}>
        <img src={IMAGE} alt={NAME} />
      </motion.a>
    );
  });

  return (
    <motion.footer variants={containerAnim} initial="init" whileInView="after" viewport={viewport}>
      {linkComponent}
    </motion.footer>
  );
}

export default Footer;
