import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import CONTENT from '../../constants/content.json';
import { getContainerAnim, getLinkAnim } from '.';
import BodyText from '../text/BodyText';

function Header({ bottom = false }) {
  if (bottom) return <BottomHeader />;
  return <TopHeader />;
}

function TopHeader() {
  // Create the links component
  const linkComponent = CONTENT.HEADER.map((val) => {
    // Get the name and link from the links array
    const { NAME, LINK } = val;

    // Return a link using the correct name and link
    return (
      <a key={NAME} href={LINK}>
        <BodyText color="yellow" customClass="bold" animate={false}>
          {NAME}
        </BodyText>
      </a>
    );
  });

  // Return the regular header
  return (
    <>
      <header>{linkComponent}</header>
      <div className="header-space" />
    </>
  );
}

function BottomHeader() {
  // Initialize the container and link animations
  const containerAnim = getContainerAnim(0.75);
  const linkAnim = getLinkAnim(50);

  // Create the links component
  const linkComponent = CONTENT.HEADER.map((val) => {
    // Get the name and link from the links array
    const { NAME, LINK } = val;

    // Create a link animation using the correct name and link
    return (
      <motion.a key={NAME} href={LINK} variants={linkAnim}>
        <BodyText color="yellow" customClass="bold" animate={false}>
          {NAME}
        </BodyText>
      </motion.a>
    );
  });

  // Return the animated header
  return (
    <motion.header className="bottom" variants={containerAnim} initial="init" animate="after">
      {linkComponent}
    </motion.header>
  );
}

Header.propTypes = {
  bottom: PropTypes.bool,
};

export default Header;
