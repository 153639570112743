import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';

import CONTENT from "../../constants/content.json";
import SubtitleText from '../text/SubtitleText';
import BodyText from '../text/BodyText';

function Experience() {
  const timelineItems = CONTENT.SECTIONS.EXPERIENCE.map((item, i, row) => {
    const { COMPANY, POSITION, START, END, DESCRIPTION, LINK, IMAGE } = item;
    const connector = i + 1 === row.length ? <></> : <TimelineConnector />;
    const padding = i + 1 === row.length ? <></> : <div className="timeline-padding" />;
    const altText = `${COMPANY} IMAGE`;

    return (
      <TimelineItem key={COMPANY} className="timeline-wrap">
        <TimelineOppositeContent>
          <a href={LINK} target="_blank" rel="noreferrer">
            <SubtitleText animate={false} color="yellow" bold>
              {COMPANY}
            </SubtitleText>
          </a>
          <BodyText bold delay={0.2}>
            {POSITION}
            <br />
            {START} to {END}
          </BodyText>
          <BodyText delay={0.4}>{DESCRIPTION}</BodyText>
          {padding}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <a href={LINK} target="_blank" rel="noreferrer">
            <TimelineDot className="timeline-dot">
              <img src={IMAGE} alt={altText} />
            </TimelineDot>
          </a>
          {connector}
        </TimelineSeparator>
      </TimelineItem>
    );
  });

  return (
    <Timeline position="left" style={{ padding: 0 }}>
      {timelineItems}
    </Timeline>
  );
}

export default Experience;
