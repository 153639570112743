import { motion } from 'framer-motion';

import { getTextAnim, textProps, viewport } from '.';

function SubtitleText({
  customClass = '',
  color = 'white',
  bold = false,
  animate = true,
  initX = 0,
  finalX = 0,
  initY = 0,
  finalY = 0,
  fadeIn = true,
  delay = 0.1,
  children,
}) {
  let className = `${customClass} c-${color}`;
  if (bold) className += ` bold`;

  // If we are not animating, return a regular H3
  if (animate === false) return <h3 className={className}>{children}</h3>;

  const textAnim = getTextAnim(initX, finalX, initY, finalY, fadeIn, delay);
  return (
    <motion.h3
      className={className}
      variants={textAnim}
      initial="init"
      whileInView="after"
      viewport={viewport}
    >
      {children}
    </motion.h3>
  );
}

SubtitleText.propTypes = textProps;

export default SubtitleText;
