import PropTypes from 'prop-types';

function Billboard({ size = 'lg', bgColor = 'black', children }) {
  // Components class
  let classes = 'billboard';

  // Change the size accordingly
  if (size === 'md') classes += ' md';

  // Change the background according to color
  if (bgColor === 'black') classes += ' bg-black';
  else if (bgColor === 'yellow') classes += ' bg-yellow';

  // Return the billboard
  return <div className={classes}>{children}</div>;
}

Billboard.propTypes = {
  size: PropTypes.oneOf(['md', 'lg']),
  bgColor: PropTypes.oneOf(['black', 'yellow']),
  children: PropTypes.node,
};

export default Billboard;
